import React from "react";
import {
  createMessage,
  MANAGE_YOUR_BILLING,
  STRIPE_LINK_TEXT,
} from "constants/Messages";
import { useDispatch } from "react-redux";
import { goToPaymentDashboard } from "actions/paymentActions";
import Analytics from "thirdparty/analytics";
import analyticEvents from "constants/AnalyticEvents";
import { Link, Text } from "design-system";

function ManageYourBilling() {
  const dispatch = useDispatch();

  const handleClick = () => {
    Analytics.track(analyticEvents.STRIPE_DASHBOARD_OPEN);
    dispatch(goToPaymentDashboard());
  };

  return (
    <div data-testid="t--manage-billing">
      <Text className="!font-semibold !mb-2" kind="action-m" renderAs="p">
        {createMessage(MANAGE_YOUR_BILLING)}
      </Text>
      <div className="flex gap-1">
        <Text kind="action-m">Visit</Text>
        <Link
          data-testid="t--stripe-dashboard-link"
          endIcon="share-box"
          kind="primary"
          onClick={handleClick}
        >
          {createMessage(STRIPE_LINK_TEXT)}
        </Link>
      </div>
    </div>
  );
}
export default ManageYourBilling;
