import styled from "styled-components";

export const Container = styled.div`
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ads-v2-color-bg);
  border-bottom: 1px solid var(--ads-v2-color-border);
  .topbar-logo {
    cursor: pointer;
  }
`;
export const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-left: 10px;
`;
export const UserInformation = styled.div`
  padding: 15px 10px;
  display: flex;
  align-items: center;

  .user-username {
    flex-basis: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-name {
    flex-basis: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-image {
    margin-right: 4px;

    div {
      cursor: default;
    }
  }
`;
