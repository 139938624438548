import log from "loglevel";
import { getLicenseDetails } from "selectors/LicenseSelectors";
import store from "store";

export default class Analytics {
  static init() {
    if (process.env.REACT_APP_SEGMENT_KEY) {
      return new Promise((resolve) => {
        const windowDoc = window as any;
        const analytics = (windowDoc.analytics = windowDoc.analytics || []);
        if (!analytics.initialize)
          if (analytics.invoked) {
            log.error("Segment snippet included twice.");
          } else {
            analytics.invoked = !0;
            analytics.methods = [
              "trackSubmit",
              "trackClick",
              "trackLink",
              "trackForm",
              "pageview",
              "identify",
              "reset",
              "group",
              "track",
              "ready",
              "alias",
              "debug",
              "page",
              "once",
              "off",
              "on",
              "addSourceMiddleware",
              "addIntegrationMiddleware",
              "setAnonymousId",
              "addDestinationMiddleware",
            ];
            analytics.factory = function (e: any) {
              return function (...args: any) {
                const t = Array.prototype.slice.call(args);
                t.unshift(e);
                analytics.push(t);
                return analytics;
              };
            };
            for (let e = 0; e < analytics.methods.length; e++) {
              const key = analytics.methods[e];
              analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key: string, e: any) {
              const t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src =
                "https://cdn.segment.com/analytics.js/v1/" +
                key +
                "/analytics.min.js";
              const n = document.getElementsByTagName("script")[0];
              n.parentNode?.insertBefore(t, n);
              analytics._loadOptions = e;
            };
            analytics._writeKey = process.env.REACT_APP_SEGMENT_KEY;
            analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load(process.env.REACT_APP_SEGMENT_KEY);
            analytics.ready(() => {
              resolve(true);
            });
            setTimeout(() => resolve(false), 2000);
            analytics.page();
          }
      });
    } else {
      return Promise.resolve(false);
    }
  }

  static identifyUser(id: string, traits: Record<string, string | boolean>) {
    if ((window as any).analytics) {
      return (window as any).analytics.identify(id, traits);
    } else {
      return false;
    }
  }

  static track(event: string, additionalData: Record<string, unknown> = {}) {
    if ((window as any).analytics) {
      const license = getLicenseDetails(store.getState());

      return (window as any).analytics.track(event, {
        segment_source_name: "customer_portal",
        licenseAccountStatus: license?.licenseAccountStatus,
        ...additionalData,
      });
    } else {
      return false;
    }
  }
}
