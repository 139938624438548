import { AppState } from "reducers";
import { createSelector } from "reselect";

export const getPayments = (state: AppState) => state.payments;

export const getIsBeingRedirectedToCheckout = createSelector(
  [getPayments],
  (payments) => payments.isBeingRedirectedToCheckout,
);

export const getIsBeingRedirectedToDashboard = createSelector(
  [getPayments],
  (payments) => payments.isBeingRedirectedToDashboard,
);
export const getIsPaymentSuccessRedirect = createSelector(
  [getPayments],
  (payments) => payments.paymentSuccessRedirect,
);
