export enum MAIN_TABS {
  DOCKER = "docker",
  KUBERNETES = "kubernetes",
  OTHER = "other",
}
export enum SUB_TABS {
  INSTALL = "install",
  UPGRADE = "upgrade",
}

export const DOCUMENTATION_GUIDE_LINK =
  "https://docs.appsmith.com/getting-started/setup";

export const GUIDES = [
  {
    id: "aws-ami",
    name: "AWS AMI",
    icon: "https://assets.appsmith.com/aws-ami.png",
    link: "https://docs.appsmith.com/getting-started/setup/installation-guides/aws-ami",
  },
  {
    id: "aws-ecs-ec2",
    name: "AWS ECS-EC2",
    icon: "https://assets.appsmith.com/aws-ec2.png",
    link: "https://docs.appsmith.com/getting-started/setup/installation-guides/aws-ecs",
  },
  {
    id: "aws-ecs-fargate",
    name: "AWS ECS Fargate",
    icon: "https://assets.appsmith.com/aws-fargate.png",
    link: "https://docs.appsmith.com/getting-started/setup/installation-guides/aws-ecs-on-fargate",
  },
  {
    id: "azure-container-instance",
    name: "Azure Container Instance",
    icon: "https://assets.appsmith.com/azure-aci.png",
    link: "https://docs.appsmith.com/getting-started/setup/installation-guides/azure-aci",
  },
];
