export enum ACTION_TYPES {
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
}

export enum ACCOUNT_STATUS_TYPES {
  // FreePlanStatusType
  FREE = "FREE",

  // BusinessPlanStatusType
  TRIAL_ACTIVE = "TRIAL_ACTIVE",
  TRIAL_EXPIRED = "TRIAL_EXPIRED",
  PAID_ACTIVE = "PAID_ACTIVE",
  PAID_EXPIRED = "PAID_EXPIRED",
  PAID_IN_GRACE_PERIOD = "PAID_IN_GRACE_PERIOD",

  //OtherStatusType
  NOT_AVAILABLE = "NOT_AVAILABLE",
  PENDING_UPDATE = "PENDING_UPDATE",
}

export const DANGER_STATES = [
  ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED,
  ACCOUNT_STATUS_TYPES.PAID_EXPIRED,
  ACCOUNT_STATUS_TYPES.NOT_AVAILABLE,
];

export const PLAN_BUILDER_STATES = [
  ACCOUNT_STATUS_TYPES.FREE,
  ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE,
  ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED,
  ACCOUNT_STATUS_TYPES.PAID_ACTIVE,
  ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD,
  ACCOUNT_STATUS_TYPES.PENDING_UPDATE,
];

export const USAGE_STATES = [
  ACCOUNT_STATUS_TYPES.PAID_ACTIVE,
  ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD,
];
