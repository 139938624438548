/* eslint-disable */
// @ts-nocheck
import smartlookClient from "smartlook-client";

export default class Smartlook {
    static init(email: string) {
        smartlookClient.init(process.env.REACT_APP_SMART_LOOK_ID || "");
        smartlookClient.identify("user", { email: email || 'anonymousPortalUser' });
    }
}
  