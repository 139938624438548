import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { createAction } from "utils/ActionUtils";
import { UserDetails } from "types/UserTypes";

export const authenticateUser = () =>
  createAction(ReduxActionTypes.AUTHENTICATE_USER);

export const authenticateUserSuccess = () =>
  createAction(ReduxActionTypes.AUTHENTICATE_USER_SUCCESS);

export const logoutUser = () => createAction(ReduxActionTypes.LOGOUT_USER);

export const updateUserDetails = (val: UserDetails) =>
  createAction(ReduxActionTypes.UPDATE_USER_DETAILS, val);

export const storeAccessToken = (val: string) =>
  createAction(ReduxActionTypes.STORE_ACCESS_TOKEN, val);
