import React from "react";
import { Container, Wrapper } from "./Loading.styles";
import { Spinner } from "design-system";

type Props = {
  inline?: boolean;
  className?: string;
};

function Loading(props: Props) {
  return (
    <Wrapper className={props.className} inline={props.inline}>
      <Container>
        <Spinner size="lg" />
      </Container>
    </Wrapper>
  );
}

export default Loading;
