import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { createReducer } from "utils/ReducerUtils";

export type PaymentsState = {
  isBeingRedirectedToCheckout: boolean;
  isBeingRedirectedToDashboard: boolean;
  paymentSuccessRedirect: boolean;
};

const initialState: PaymentsState = {
  isBeingRedirectedToCheckout: false,
  isBeingRedirectedToDashboard: false,
  paymentSuccessRedirect: false,
};

export const paymentsReducer = createReducer(initialState, {
  [ReduxActionTypes.INITIATE_CHECKOUT]: (state: PaymentsState) => {
    return {
      ...state,
      isBeingRedirectedToCheckout: true,
    };
  },
  [ReduxActionTypes.INITIATE_CHECKOUT_FAILED]: (state: PaymentsState) => {
    return {
      ...state,
      isBeingRedirectedToCheckout: false,
    };
  },
  [ReduxActionTypes.GO_TO_PAYMENT_DASHBOARD]: (state: PaymentsState) => {
    return {
      ...state,
      isBeingRedirectedToDashboard: true,
    };
  },
  [ReduxActionTypes.GO_TO_PAYMENT_DASHBOARD_FAILED]: (state: PaymentsState) => {
    return {
      ...state,
      isBeingRedirectedToDashboard: false,
    };
  },
  [ReduxActionTypes.PAYMENT_SUCCESS_REDIRECT]: (
    state: PaymentsState,
    action,
  ) => {
    return {
      ...state,
      paymentSuccessRedirect: action.payload,
    };
  },
});
