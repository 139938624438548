import React, { useMemo } from "react";
import {
  SidebarContainer,
  LinkItem,
  ListContainer,
  SidebarUpgradeBanner,
} from "./SidebarMenu.styles";
import useSidebarItems from "./SidebarItems";
import {
  BILLING_CONSOLE_TEXT,
  SIDEBAR_BANNER_DESCRIPTION,
  SIDEBAR_BANNER_HEADING,
  START_TRIAL_CTA,
  createMessage,
} from "constants/Messages";
import Analytics from "thirdparty/analytics";
import { Button, Icon, Text } from "design-system";
import { useDispatch, useSelector } from "react-redux";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { getAccountType } from "selectors/AccountSelectors";
import { startTrial } from "actions/LicenseActions";
import { trialLoader } from "selectors/LicenseSelectors";

function SidebarBanner() {
  const dispatch = useDispatch();
  const isTrialLoading = useSelector(trialLoader);

  const handleStartTrial = () => {
    dispatch(startTrial());
  };

  return (
    <SidebarUpgradeBanner>
      <img
        alt="upgrade-banner"
        className="mb-3"
        src="https://assets.appsmith.com/be-trial-cs.svg"
      />
      <Text className="!font-semibold !mb-4" kind="heading-s" renderAs="p">
        {createMessage(SIDEBAR_BANNER_HEADING)}
      </Text>
      <Text className="!mb-6" kind="body-m" renderAs="p">
        {createMessage(SIDEBAR_BANNER_DESCRIPTION)}
      </Text>
      <Button
        data-testid="sidebar-start-trial-btn"
        isLoading={isTrialLoading}
        kind="primary"
        onClick={handleStartTrial}
        size="md"
      >
        {createMessage(START_TRIAL_CTA)}
      </Button>
    </SidebarUpgradeBanner>
  );
}

export function Menu() {
  const items = useSidebarItems();
  const accountType = useSelector(getAccountType);

  const canShowUpgradeBanner = useMemo(() => {
    return accountType === ACCOUNT_STATUS_TYPES.FREE;
  }, [accountType]);

  return (
    <SidebarContainer data-testid="sidebar">
      <div>
        <Text kind="heading-s">{createMessage(BILLING_CONSOLE_TEXT)}</Text>
        <ListContainer>
          {items
            .filter((item) => item.isVisible)
            .map(({ icon, id, path, segmentEvent, text }) => (
              <LinkItem
                data-testid={"sidebar-" + id}
                key={id}
                onClick={() => Analytics.track(segmentEvent)}
                to={path}
              >
                <Icon
                  color="var(--ads-v2-color-fg-emphasis)"
                  name={icon}
                  size="md"
                />
                <Text kind="body-m">{text}</Text>
              </LinkItem>
            ))}
        </ListContainer>
      </div>
      {canShowUpgradeBanner && <SidebarBanner />}
    </SidebarContainer>
  );
}
