export const BASE_PATH = "/api/v1";

export const BASE_API_REQUEST_CONFIG = {
  baseURL: BASE_PATH,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const AXIOS_CONNECTION_ABORT_CODE = "ECONNABORTED";
