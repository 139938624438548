import { LICENSE_PLAN, PRODUCT_EDITION } from "constants/LicenseConstants";
import {
  createMessage,
  SELF_SERVE,
  ENTERPRISE,
  AIRGAPPED,
  NOT_AVAILABLE,
} from "constants/Messages";

export const getLicensePlanText = (
  licensePlan: LICENSE_PLAN,
  productEdition: PRODUCT_EDITION,
) => {
  if (licensePlan === LICENSE_PLAN.BUSINESS) return createMessage(SELF_SERVE);
  else if (licensePlan === LICENSE_PLAN.ENTERPRISE) {
    if (productEdition === PRODUCT_EDITION.AIR_GAP) {
      return createMessage(AIRGAPPED);
    }
    return createMessage(ENTERPRISE);
  } else {
    return createMessage(NOT_AVAILABLE);
  }
};
