import React, { useRef } from "react";
import { Container } from "./PlansInfo.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  ALL_PLANS,
  PLAN_NAME,
  PLANS_TABLE_STRUCTURE,
} from "constants/PlanConstants";
import { Button, Icon, Text } from "design-system";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import { getIsBeingRedirectedToCheckout } from "selectors/PaymentsSelectors";
import { initiateCheckout } from "actions/paymentActions";
import {
  getAccountType,
  isBusinessAccount,
  isEnterpriseAccount,
  isFreeAccount,
} from "selectors/AccountSelectors";
import { startTrial } from "actions/LicenseActions";
import { LICENSE_PLAN } from "constants/LicenseConstants";
import { getLicensePlan, trialLoader } from "selectors/LicenseSelectors";
import {
  CONTACT_US_CTA,
  CURRENT_PLAN_CTA,
  ENTERPRISE_CONTACT_MESSAGE_INTERCOM,
  START_TRIAL_CTA,
  UPGRADE_TEXT,
  createMessage,
} from "constants/Messages";
import {
  startConversationOnIntercom,
  textForIntercomMessage,
} from "utils/IntercomUtils";

function StartBusinessTrialButton() {
  const dispatch = useDispatch();
  const startTrialLoader = useSelector(trialLoader);

  return (
    <Button
      UNSAFE_height="36px"
      className="mb-2"
      isLoading={startTrialLoader}
      onClick={() => dispatch(startTrial())}
    >
      <Text className="!text-sm" kind="body-m">
        {createMessage(START_TRIAL_CTA)}
      </Text>
    </Button>
  );
}

function UpgradeButton() {
  const dispatch = useDispatch();
  const isBeingRedirectedToCheckout = useSelector(
    getIsBeingRedirectedToCheckout,
  );
  return (
    <Button
      UNSAFE_height="36px"
      className="mb-2"
      data-testid="checkout-btn"
      isLoading={isBeingRedirectedToCheckout}
      onClick={() => dispatch(initiateCheckout())}
    >
      <Text className="!text-sm" kind="body-m">
        {createMessage(UPGRADE_TEXT)}
      </Text>
    </Button>
  );
}

function EnterpriseContactButton() {
  const accountType = useSelector(getAccountType);
  const isEnterprise = useSelector(isEnterpriseAccount);

  const showIntercomWithText = () => {
    let message = textForIntercomMessage(
      accountType as ACCOUNT_STATUS_TYPES,
      isEnterprise,
    );
    if (!message) {
      message = createMessage(ENTERPRISE_CONTACT_MESSAGE_INTERCOM);
    }
    startConversationOnIntercom(message);
  };

  return (
    <Button
      UNSAFE_height="36px"
      className="mb-2"
      kind="secondary"
      onClick={showIntercomWithText}
    >
      <Text className="!text-sm" kind="body-m">
        {createMessage(CONTACT_US_CTA)}
      </Text>
    </Button>
  );
}

function CurrentPlanButton() {
  return (
    <Button UNSAFE_height="36px" className="mb-2" isDisabled kind="secondary">
      <Text className="!text-sm" kind="body-m">
        {createMessage(CURRENT_PLAN_CTA)}
      </Text>
    </Button>
  );
}

function ButtonForFreeLicensePlan({ planName }: { planName: PLAN_NAME }) {
  if (planName === PLAN_NAME.COMMUNITY) {
    return <CurrentPlanButton />;
  }
  if (planName === PLAN_NAME.BUSINESS) {
    return <StartBusinessTrialButton />;
  }
  if (planName === PLAN_NAME.ENTERPRISE) {
    return <EnterpriseContactButton />;
  }
  return null;
}

function ButtonForSelfServeLicensePlan({ planName }: { planName: PLAN_NAME }) {
  const licenseType = useSelector(getAccountType);
  if (planName === PLAN_NAME.ENTERPRISE) {
    return <EnterpriseContactButton />;
  } else if (planName === PLAN_NAME.COMMUNITY) {
    return null;
  } else {
    if (
      licenseType === ACCOUNT_STATUS_TYPES.PAID_ACTIVE ||
      licenseType === ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD
    ) {
      return <CurrentPlanButton />;
    } else if (
      licenseType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE ||
      licenseType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED ||
      licenseType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED
    ) {
      return <UpgradeButton />;
    }
  }
  return null;
}

function ButtonForEnterpriseLicensePlan({
  accountType,
  planName,
}: {
  planName: PLAN_NAME;
  accountType: ACCOUNT_STATUS_TYPES;
}) {
  if (planName === PLAN_NAME.ENTERPRISE) {
    if (
      accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED ||
      accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE
    ) {
      return <EnterpriseContactButton />;
    }
    return <CurrentPlanButton />;
  }
  return null;
}

function UpgradeDowngradeButton({
  accountType,
  planName,
}: {
  accountType: ACCOUNT_STATUS_TYPES;
  planName: PLAN_NAME;
}) {
  const freeAccount = useSelector(isFreeAccount);
  const businessAccount = useSelector(isBusinessAccount);
  const enterpriseAccount = useSelector(isEnterpriseAccount);

  if (freeAccount) return <ButtonForFreeLicensePlan planName={planName} />;
  if (businessAccount)
    return <ButtonForSelfServeLicensePlan planName={planName} />;
  if (enterpriseAccount)
    return (
      <ButtonForEnterpriseLicensePlan
        accountType={accountType}
        planName={planName}
      />
    );

  return null;
}

function PlansInfo() {
  const ref = useRef<HTMLDivElement>(null);
  const freeAccount = useSelector(isFreeAccount);
  const businessAccount = useSelector(isBusinessAccount);
  const enterpriseAccount = useSelector(isEnterpriseAccount);

  const licensePlan = useSelector(getLicensePlan);
  const accountType = useSelector(getAccountType);

  const isActivePlan = (planName: PLAN_NAME) => {
    const planToAccountMapper = {
      [PLAN_NAME.COMMUNITY]: freeAccount,
      [PLAN_NAME.BUSINESS]: businessAccount,
      [PLAN_NAME.ENTERPRISE]: enterpriseAccount,
    };
    return planToAccountMapper[planName];
  };

  const isBusinessTrial = (planName: PLAN_NAME) => {
    return (
      planName === PLAN_NAME.BUSINESS &&
      licensePlan === LICENSE_PLAN.BUSINESS &&
      (accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE ||
        accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED)
    );
  };
  const isEnterpriseTrial = (planName: PLAN_NAME) => {
    return (
      planName === PLAN_NAME.ENTERPRISE &&
      licensePlan === LICENSE_PLAN.ENTERPRISE &&
      (accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE ||
        accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED)
    );
  };

  const featureValue = (feature: any) => {
    const val = Object.values(feature)[0];
    return typeof val === "boolean" ? (
      <Icon
        color={
          val
            ? "var(--ads-v2-color-fg-success)"
            : "var(--ads-v2-color-fg-on-error)"
        }
        name={val ? "oval-check" : "close-circle-line"}
        size="md"
      />
    ) : (
      String(val)
    );
  };

  return (
    <Container data-testid="plans-table" ref={ref}>
      <div className="heading">
        <div className="heading-row">
          <Text className="!font-semibold" kind="heading-s">
            Features
          </Text>
        </div>
        {ALL_PLANS.map((plan) => (
          <div
            className={`heading-row ${
              isActivePlan(plan.name) ? "active-plan" : ""
            }`}
            key={plan.name}
          >
            <img src={require(`../../assets/icons/${plan.iconName}`)} />
            <Text className="!font-semibold" kind="heading-s">
              {plan.name}{" "}
              {isBusinessTrial(plan.name) || isEnterpriseTrial(plan.name)
                ? `• Trial`
                : ""}
            </Text>
            {isActivePlan(plan.name) && (
              <Icon
                color={
                  accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED ||
                  accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED
                    ? "var(--ads-v2-color-fg-warning)"
                    : "var(--ads-v2-color-fg-success)"
                }
                name={
                  accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED ||
                  accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED
                    ? "warning-line"
                    : "oval-check"
                }
                size="lg"
              />
            )}
          </div>
        ))}
      </div>
      <div className="heading-description-row">
        <div className="heading-column" />
        {ALL_PLANS.map((plan) => (
          <div
            className={`heading-column ${
              isActivePlan(plan.name) ? "active-plan" : ""
            }`}
            key={plan.name}
          >
            <div>
              {plan.details.map((item) => (
                <div dangerouslySetInnerHTML={{ __html: item }} key={item} />
              ))}
            </div>
            <UpgradeDowngradeButton
              accountType={accountType as ACCOUNT_STATUS_TYPES}
              planName={plan.name}
            />
          </div>
        ))}
      </div>
      {PLANS_TABLE_STRUCTURE.map((section, idx) => (
        <div key={section.name + idx}>
          <div className="section-row">
            <Text kind="heading-xs">{section.name}</Text>
          </div>
          {section.features.map((feature, idx) => (
            <div className="feature-row" key={feature + idx}>
              <div className="table-column">{feature}</div>
              {ALL_PLANS.map((plan) => (
                <div
                  className={`table-column ${
                    isActivePlan(plan.name) ? "active-plan" : ""
                  }`}
                  key={feature + plan.name + idx}
                >
                  {featureValue(plan.features[section.name][idx])}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </Container>
  );
}

export default PlansInfo;
