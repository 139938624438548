import React from "react";
import { VERIFY_EMAIL_BANNER } from "constants/CommonConstant";
import {
  createMessage,
  VERIFY_EMAIL_BODY,
  VERIFY_EMAIL_HEADER,
} from "constants/Messages";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getIsUserEmailVerified, getUserEmail } from "selectors/UserSelectors";
import { withAuthentication } from "utils/RouterUtils";
import { Text } from "design-system";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

function VerifyEmail() {
  const userEmail = useSelector(getUserEmail);
  const isEmailVerified = useSelector(getIsUserEmailVerified);

  if (isEmailVerified) {
    return <Navigate to={`/license`} />;
  } else {
    return (
      <Wrapper>
        <div className="text-center">
          <img
            className="mx-auto"
            data-testid="verify-image"
            src={VERIFY_EMAIL_BANNER}
          />
          <Text kind="heading-l" renderAs="h3">
            {createMessage(VERIFY_EMAIL_HEADER)}
          </Text>
          <Text kind="body-m">
            {createMessage(VERIFY_EMAIL_BODY, userEmail)}
          </Text>
        </div>
      </Wrapper>
    );
  }
}

export default withAuthentication(VerifyEmail);
