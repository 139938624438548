import React from "react";
import CommandBox from "../CommandBox";
import { Step } from "../DocumentViewer";
import { GuideWrapper, RedStyling } from "../DocumentViewer.styles";
import { Text } from "design-system";
function KubernetesInstallGuide() {
  return (
    <>
      <Text data-testid="kubernetes-install-guide" kind="heading-xs">
        Follow the steps below to install Appsmith on your Kubernetes cluster:
      </Text>
      <GuideWrapper>
        <Step count={1}>
          <Text>Add the Appsmith appsmith-ee chart repository.</Text>
          <CommandBox>
            helm repo <RedStyling>add</RedStyling> appsmith-ee
            https://helm-ee.appsmith.com
          </CommandBox>
        </Step>

        <Step count={2}>
          <Text>
            Enter the following command to load the Appsmith chart repository.
          </Text>
          <CommandBox>helm repo update</CommandBox>
        </Step>

        <Step count={3}>
          <Text>
            Generate the <code>values.yaml</code> file.
          </Text>
          <CommandBox>
            {"helm show values appsmith-ee/appsmith > values.yaml"}
          </CommandBox>
        </Step>

        <Step count={4}>
          <Text>Run the below command to deploy Appsmith:</Text>
          <CommandBox>
            helm <RedStyling>install</RedStyling> appsmith appsmith-ee/appsmith
            -n {"<namespace>"} -f values.yaml
          </CommandBox>
        </Step>

        <Step count={5}>
          <Text>
            Create an Appsmith account and enter your license key to activate
            the instance.
          </Text>
        </Step>
      </GuideWrapper>
    </>
  );
}
export default KubernetesInstallGuide;
