import React from "react";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { Avatar } from "design-system";
import { Container, UserNameWrapper, UserInformation } from "./Topbar.styles";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "actions/UserActions";
import {
  Menu,
  MenuItem,
  MenuContent,
  MenuTrigger,
  MenuSeparator,
  Text,
} from "design-system";
import { getUserDetails } from "selectors/UserSelectors";
import { useNavigate } from "react-router-dom";
import {
  SIGN_OUT_TEXT,
  YOUR_ACCOUNT_TEXT,
  createMessage,
} from "constants/Messages";

function Topbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUserDetails);

  const Profile = () => (
    <Avatar
      className="cursor-pointer"
      data-testid="avatar-container"
      firstLetter={user?.name ? user.name?.toUpperCase().slice(0, 2) : "UN"}
      label={createMessage(YOUR_ACCOUNT_TEXT)}
    />
  );

  return (
    <Container>
      <Logo
        className="topbar-logo"
        data-testid="appsmith-logo"
        onClick={() => navigate("/")}
      />
      <Menu>
        <MenuTrigger>{Profile()}</MenuTrigger>
        <MenuContent align="end">
          <UserInformation>
            <Profile />
            <UserNameWrapper>
              <div className="user-username">
                <Text kind="body-s">{user?.email || ""}</Text>
              </div>
            </UserNameWrapper>
          </UserInformation>
          <MenuSeparator />
          <MenuItem
            className="t--logout-icon signout-option"
            onSelect={() => dispatch(logoutUser())}
            startIcon="logout"
          >
            {createMessage(SIGN_OUT_TEXT)}
          </MenuItem>
        </MenuContent>
      </Menu>
    </Container>
  );
}

export default Topbar;
