import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "features/layout/Layout";
import Billing from "pages/billing/Billing";
import License from "pages/license/License";
import Plans from "pages/plans/Plans";
import VerifyEmail from "pages/verify-email";

function AppRouter() {
  return (
    <Routes>
      <Route element={<Layout />} path="/">
        <Route element={<Navigate to={`/license`} />} path="/" />
        <Route element={<License />} path="license" />
        <Route element={<Plans />} path="plans" />
        <Route element={<Billing />} path="billing" />
        <Route element={<VerifyEmail />} path="verify-email" />

        {/* Catch-all route for any other paths */}
        <Route element={<Navigate to="/license" />} path="*" />
      </Route>
    </Routes>
  );
}
export default AppRouter;
