import { AppState } from "reducers";
import { createSelector } from "reselect";
import { usageReducer } from "./UsageSelectors";

export const userReducer = (state: AppState) => state.user;

export const getToken = createSelector(
  [userReducer],
  (userSlice) => userSlice.token,
);

export const isUserAuthenticated = createSelector(
  [userReducer],
  (userSlice) => userSlice.isAuthenticated,
);

export const getUserDetails = createSelector(
  [userReducer],
  (userSlice) => userSlice.details,
);

export const getIsUserEmailVerified = createSelector(
  [isUserAuthenticated, getUserDetails],
  (isAuthenticated, user) => {
    return isAuthenticated && user?.email_verified;
  },
);

export const getUserEmail = createSelector([getUserDetails], (user) => {
  return user ? user.email : "";
});

export const isUserAuthenticatedandUsageLoaded = createSelector(
  [userReducer, usageReducer],
  (userSlice, usageSlice) =>
    userSlice.isAuthenticated && !usageSlice?.isLoading,
);
