import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import {
  BUSINESS_ENTERPRISE_PAID_EXPIRED_INTERCOM,
  ENTERPRISE_CONTACT_MESSAGE_INTERCOM,
  ENTERPRISE_IN_GRACE_PERIOD_INTERCOM,
  ENTERPRISE_TRIAL_ACTIVE_INTERCOM,
  ENTERPRISE_TRIAL_EXPIRED_INTERCOM,
  createMessage,
} from "constants/Messages";

declare global {
  interface Window {
    Intercom: any;
  }
}
export const showIntercomPopup = () => {
  if (window && window.Intercom) {
    window.Intercom("show");
  }
};

export const textForIntercomMessage = (
  accountType: ACCOUNT_STATUS_TYPES,
  isEnterprise?: boolean,
) => {
  let message = "";
  if (accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED)
    message = createMessage(BUSINESS_ENTERPRISE_PAID_EXPIRED_INTERCOM);
  else if (isEnterprise) {
    switch (accountType) {
      case ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE:
        message = createMessage(ENTERPRISE_TRIAL_ACTIVE_INTERCOM);
        break;
      case ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED:
        message = createMessage(ENTERPRISE_TRIAL_EXPIRED_INTERCOM);
        break;
      case ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD:
        message = createMessage(ENTERPRISE_IN_GRACE_PERIOD_INTERCOM);
        break;
      default:
        message = createMessage(ENTERPRISE_CONTACT_MESSAGE_INTERCOM);
    }
  }
  return message;
};
export const startConversationOnIntercom = (message: string) => {
  if (window && window.Intercom) {
    window.Intercom("showNewMessage", message);
  }
};
