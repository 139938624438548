import React from "react";
import {
  ACCOUNT_STATUS_TYPES,
  DANGER_STATES,
} from "constants/AccountTypeConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentLicenseExpiry,
  getLicenseOrigin,
  isLicenseOriginSelfServe,
} from "selectors/LicenseSelectors";
import {
  CONTACT_US_CTA,
  createMessage,
  PAID_EXPIRED,
  PAYMENT_FAILED_SELF_SERVE,
  PAYMENT_FAILED_SALES,
  PAYMENT_FAILED_ENTERPRISE,
  PAYMENT_FAILED_ENTERPRISE_CONTACT,
  PAYMENT_FAILED_UPDATE_SELF_SERVE,
  PAYMENT_FAILED_UPDATE_SALES,
  TRIAL_ACTIVE,
  TRIAL_EXPIRED,
  TRIAL_EXPIRED_ENTERPRISE,
  UPGRADE,
  UPGRADE_CTA,
  UPGRADE_ENTERPRISE,
  VISIT_STRIPE_PORTAL_CTA,
  PAID_EXPIRED_SELF_SERVE,
} from "constants/Messages";
import { PLANS } from "constants/RouteContants";
import { getRemainingDays } from "utils/CommonUtils";
import Analytics from "thirdparty/analytics";
import analyticEvents from "constants/AnalyticEvents";
import { goToPaymentDashboard, initiateCheckout } from "actions/paymentActions";
import {
  getAccountType,
  isEnterpriseAccount,
} from "selectors/AccountSelectors";
import { StyledBanner } from "./Warningbar.styles";
import {
  showIntercomPopup,
  startConversationOnIntercom,
  textForIntercomMessage,
} from "utils/IntercomUtils";
import { LICENSE_ORIGIN } from "constants/LicenseConstants";

function getBannerMessage(
  accountType: string,
  licenseExpiry: number | undefined,
  licenseOrigin: LICENSE_ORIGIN | "",
  isEnterprise: boolean,
): any {
  let message = "";
  let messageSuffix = "";
  const remainingTime = getRemainingDays(licenseExpiry);

  const isDanger = () => {
    if (
      isEnterprise &&
      accountType === ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD
    ) {
      return false;
    }
    return (
      DANGER_STATES.includes(accountType as ACCOUNT_STATUS_TYPES) ||
      (isEnterprise &&
        accountType === ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD) ||
      remainingTime.suffix.includes("hour") ||
      remainingTime.count <= 3
    );
  };

  switch (accountType) {
    case ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE:
      message = createMessage(TRIAL_ACTIVE, remainingTime);
      messageSuffix = createMessage(
        isEnterprise ? UPGRADE_ENTERPRISE : UPGRADE,
      );
      break;
    case ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED:
      message = createMessage(
        isEnterprise ? TRIAL_EXPIRED_ENTERPRISE : TRIAL_EXPIRED,
      );
      messageSuffix = createMessage(
        isEnterprise ? UPGRADE_ENTERPRISE : UPGRADE,
      );
      break;
    case ACCOUNT_STATUS_TYPES.PAID_EXPIRED:
      message =
        licenseOrigin === LICENSE_ORIGIN.SELF_SERVE
          ? createMessage(PAID_EXPIRED_SELF_SERVE)
          : createMessage(PAID_EXPIRED);
      messageSuffix = "";
      break;
    case ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD:
      message = isEnterprise
        ? createMessage(PAYMENT_FAILED_ENTERPRISE)
        : licenseOrigin === LICENSE_ORIGIN.SELF_SERVE
        ? createMessage(PAYMENT_FAILED_SELF_SERVE)
        : createMessage(PAYMENT_FAILED_SALES);
      messageSuffix = isEnterprise
        ? createMessage(PAYMENT_FAILED_ENTERPRISE_CONTACT)
        : licenseOrigin === LICENSE_ORIGIN.SELF_SERVE
        ? createMessage(PAYMENT_FAILED_UPDATE_SELF_SERVE, remainingTime)
        : createMessage(PAYMENT_FAILED_UPDATE_SALES);
      break;
    default:
      break;
  }
  return {
    message: (
      <>
        {message}
        &nbsp;
        {messageSuffix}
      </>
    ),
    kind: isDanger() ? "error" : "warning",
  };
}

function Warningbar() {
  const accountType = useSelector(getAccountType);
  const licenseExpiry = useSelector(getCurrentLicenseExpiry);
  const isEnterprise = useSelector(isEnterpriseAccount);
  const licenseOrigin = useSelector(getLicenseOrigin);
  const isSelfServe = useSelector(isLicenseOriginSelfServe);

  const dispatch = useDispatch();

  const handleUpdate = () => {
    Analytics.track(analyticEvents.UPDATE_CLICK_PORTAL_PAYMENT_FAILURE);
    dispatch(goToPaymentDashboard());
  };

  const handleUpgrade = () => {
    Analytics.track(analyticEvents.UPGRADE_CLICK_PORTAL_BANNER);
    dispatch(initiateCheckout());
  };

  const showIntercomWithText = () => {
    const message = textForIntercomMessage(
      accountType as ACCOUNT_STATUS_TYPES,
      isEnterprise,
    );
    if (message) {
      startConversationOnIntercom(message);
    }
  };

  const getActionLinks = () => {
    let defaultAction: { children: string; onClick?: () => void } = {
      children: "",
    };
    if (
      (!isSelfServe && accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED) ||
      (isEnterprise &&
        (accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED ||
          accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE))
    ) {
      defaultAction = {
        children: createMessage(CONTACT_US_CTA),
        onClick: showIntercomWithText,
      };
    } else if (accountType === ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD) {
      defaultAction = {
        children: isEnterprise
          ? createMessage(CONTACT_US_CTA)
          : createMessage(VISIT_STRIPE_PORTAL_CTA),
        onClick: isEnterprise ? showIntercomPopup : handleUpdate,
      };
    } else if (!window.location.pathname.endsWith(PLANS)) {
      defaultAction = {
        children: createMessage(UPGRADE_CTA),
        onClick: handleUpgrade,
      };
    }
    return defaultAction;
  };

  const { kind, message } = getBannerMessage(
    accountType,
    licenseExpiry,
    licenseOrigin,
    isEnterprise,
  );

  return (
    <StyledBanner
      className="warning-bar"
      isClosable={false}
      kind={kind}
      link={{ ...getActionLinks() }}
    >
      {message}
    </StyledBanner>
  );
}

export default Warningbar;
