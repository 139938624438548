import styled from "styled-components";

export const Wrapper = styled.div<{ inline?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${(props) => {
    if (props.inline) {
      return `
        height: 100%;
        width: 100%;
        top: -50%;
        left: -50%;
        transform: translate(50%, 50%);
      `;
    } else {
      return `
        height: 100vh;
        width: 100vw;
      `;
    }
  }}
`;

export const Container = styled.div`
  display: inline-block;
  position: relative;
  .cs-spinner {
    width: 60px;
    height: 60px;
  }
`;
