import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { ReduxAction } from "types/ReduxTypes";
import { UserDetails } from "types/UserTypes";
import { createReducer } from "utils/ReducerUtils";

export type UserStateType = {
  details: UserDetails | null | undefined;
  isLoading: boolean;
  error: string | null;
  token: string;
  isAuthenticated: boolean;
};

const initialState: UserStateType = {
  details: null,
  error: "",
  token: "",
  isLoading: false,
  isAuthenticated: false,
};

export const userReducer = createReducer(initialState, {
  [ReduxActionTypes.UPDATE_USER_DETAILS]: (
    state: UserStateType,
    action: ReduxAction<UserStateType>,
  ) => {
    return {
      ...state,
      details: { ...action.payload },
    };
  },
  [ReduxActionTypes.STORE_ACCESS_TOKEN]: (
    state: UserStateType,
    action: ReduxAction<UserStateType>,
  ) => {
    return {
      ...state,
      token: action.payload,
    };
  },
  [ReduxActionTypes.AUTHENTICATE_USER_SUCCESS]: (state: UserStateType) => {
    return {
      ...state,
      isAuthenticated: true,
    };
  },
});
