import styled from "styled-components";
import { FONT } from "constants/styles";

export const UsageCard = styled.div`
  padding: 28px 30px;
  background-color: var(--ads-v2-color-bg);
  border-radius: 10px;
  border: 1px solid var(--ads-v2-color-border);
  margin-bottom: 24px;
  margin-top: 12px;
`;
export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;
export const DateHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const InnerBody = styled.div`
  position: relative;
  padding: 0 20px 0 38px;
  margin-top: 2px;
  font-weight: ${FONT.WEIGHT_400};
  line-height: 14px;
  min-height: 100px;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  :first-child,
  :last-child {
    border: none;
  }
`;
export const RowItemHeading = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  svg {
    margin-left: 4px;
  }
  font-weight: ${FONT.WEIGHT_500};
  color: var(--ads-v2-color-fg);
`;
export const RowItem = styled.div`
  width: 15%;
  text-align: center;
`;
export const StyledDivTag = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

export const StyledDivTagWrapper = styled.div`
  display: flex;
`;

export const EmailRow = styled(Row)`
  color: var(--ads-v2-color-fg);
  display: flex;
  justify-content: flex-start;
`;

export const Wrapper = styled.div``;

export const ProgressBackground = styled.div`
  background-color: var(--ads-v2-color-bg-subtle);
`;

export const ProgressForeground = styled.div`
  background-color: var(--ads-v2-color-bg-brand);
`;
