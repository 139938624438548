import React from "react";
import Loading from "components/loading/Loading";
import {
  createMessage,
  SEATS_PURCHASED,
  ACTIVE_USERS,
  SALES_EMAIL,
  REACH_OUT,
  HOURS_USED,
  TOOLTIP_SEATS_PURCHASED,
  TOOLTIP_ACTIVE_USERS,
  FREE_SESSIONS_TEXT,
} from "constants/Messages";
import { Tooltip, Icon, Text, Link } from "design-system";
import {
  InnerBody,
  Row,
  RowItemHeading,
  RowItem,
  Wrapper,
  EmailRow,
  ProgressBackground,
  ProgressForeground,
} from "./EnterpriseUsage.styles";
import { UsageError } from "../UsageError";
import { useSelector } from "react-redux";
import {
  getIsUsageLoading,
  getIsUsageAvailable,
  getUsageDetailsForEnterprise,
  getEnterpriseSubscriptionType,
} from "selectors/UsageSelectors";
import { ENTERPRISE_LICENSE_TYPES } from "constants/LicenseConstants";
import { APPSMITH_SALES_EMAIL } from "constants/CommonConstant";

export default function EnterpriseUsageDetails() {
  const isLoading = useSelector(getIsUsageLoading);
  const isUsageAvailable = useSelector(getIsUsageAvailable);
  const usage = useSelector(getUsageDetailsForEnterprise);
  const mailTo = `mailto:${APPSMITH_SALES_EMAIL}`;
  const enterpriseSubscriptionType = useSelector(getEnterpriseSubscriptionType);
  const showUsageCard =
    (enterpriseSubscriptionType === ENTERPRISE_LICENSE_TYPES.USER_USAGE ||
      enterpriseSubscriptionType === ENTERPRISE_LICENSE_TYPES.USAGE) &&
    usage?.hoursPurchased > 0;

  const hoursUsage = usage?.hoursUsed ?? 0;
  const freeSessions = usage?.hoursFreeSessions ?? 0;
  const hasFreeSessions = freeSessions > 0 && showUsageCard;
  const totalSessions = (usage?.hoursPurchased ?? 0) + freeSessions;
  const hoursUsageWidth =
    usage?.hoursUsed > totalSessions ? 100 : (hoursUsage / totalSessions) * 100;

  return (
    <InnerBody>
      {isLoading || !isUsageAvailable ? (
        isLoading ? (
          <Loading inline />
        ) : (
          <UsageError />
        )
      ) : (
        <Wrapper className="mt-5" data-testid="enterprise-card">
          <Row data-testid="seats-purchased">
            <RowItemHeading>
              <Text renderAs="span">{createMessage(SEATS_PURCHASED)}</Text>
              <Tooltip content={createMessage(TOOLTIP_SEATS_PURCHASED)}>
                <Icon name="question" size="md" />
              </Tooltip>
              <RowItem>{usage?.seatsPurchased ?? 0}</RowItem>
            </RowItemHeading>
            <RowItemHeading>
              {createMessage(ACTIVE_USERS)}
              <Tooltip content={createMessage(TOOLTIP_ACTIVE_USERS)}>
                <Icon name="question" size="md" />
              </Tooltip>
              <RowItem>{usage?.activeUsers ?? 0}</RowItem>
            </RowItemHeading>
          </Row>
          <hr />
          {showUsageCard && (
            <Wrapper>
              <Row className="!border-0" data-testid="hours-purchased">
                <RowItemHeading className="justify-between !w-full">
                  <div className="flex">
                    <Text renderAs="span">{createMessage(HOURS_USED)}</Text>
                  </div>
                  <RowItem className="!text-right">
                    {usage?.hoursUsed}/{totalSessions}
                  </RowItem>
                </RowItemHeading>
              </Row>
              <ProgressBackground
                className="w-full rounded-full h-2.5"
                data-testid="hours-progress-bar"
              >
                <ProgressForeground
                  className="h-2.5 rounded-full"
                  style={{ width: `${hoursUsageWidth}%` }}
                />
              </ProgressBackground>
            </Wrapper>
          )}

          {hasFreeSessions && (
            <div className="mt-4">
              <Text>
                {createMessage(
                  FREE_SESSIONS_TEXT,
                  totalSessions,
                  usage?.hoursFreeSessions,
                )}
              </Text>
            </div>
          )}
          <EmailRow>
            <Text renderAs="span">{createMessage(REACH_OUT)} </Text>
            <div className="!ml-1">
              <Link kind="secondary" to={mailTo}>
                {createMessage(SALES_EMAIL)}
              </Link>
            </div>
          </EmailRow>
        </Wrapper>
      )}
    </InnerBody>
  );
}
