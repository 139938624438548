import React from "react";
import { Tag, Text } from "design-system";
import { useSelector } from "react-redux";
import {
  getSubscriptionEndDate,
  getSubscriptionStartDate,
} from "selectors/UsageSelectors";
import { getDateString } from "utils/CommonUtils";
import SelfServeUsageDetails from "./SelfServeUsageDetails";
import {
  DateHeading,
  Header,
  StyledDivTag,
  StyledDivTagWrapper,
  UsageCard,
} from "../common.styles";
import { CURRENT_CYCLE_TEXT, createMessage } from "constants/Messages";
import { getLicensePlanText } from "utils/LicenseUtils";
import { getLicensePlan, getProductEdition } from "selectors/LicenseSelectors";

function SelfServeUsage() {
  const startDate = useSelector(getSubscriptionStartDate);
  const endDate = useSelector(getSubscriptionEndDate);
  const licensePlan = useSelector(getLicensePlan);
  const productEdition = useSelector(getProductEdition);

  return (
    <UsageCard data-testid="t--selfserve-usage-card">
      <Header>
        <DateHeading>
          <StyledDivTagWrapper>
            <Text className="!font-semibold" kind="heading-s">
              {createMessage(CURRENT_CYCLE_TEXT)}
            </Text>
            <StyledDivTag>
              <Tag isClosable={false}>
                {getLicensePlanText(licensePlan, productEdition)}
              </Tag>
            </StyledDivTag>
          </StyledDivTagWrapper>

          {!!startDate && !!endDate && (
            <Text
              color="var(--ads-v2-color-gray-500)"
              data-testid="t--selfserve-usage-date"
              kind="body-s"
            >
              {getDateString(startDate)}
              <span> - </span>
              {getDateString(endDate)}
            </Text>
          )}
        </DateHeading>
      </Header>
      <SelfServeUsageDetails />
    </UsageCard>
  );
}

export default SelfServeUsage;
