import { takeLatest, put, all, call } from "redux-saga/effects";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import {
  fetchLicenseDetailsSuccess,
  fetchLicenseDetailsFailed,
  startTrialFailed,
} from "actions/LicenseActions";
import LicenseApi from "api/LicenseApi";
import { ApiResponse } from "types/ApiTypes";
import { LicenseResponseType } from "types/LicenseTypes";
import { authenticateUserSuccess } from "actions/UserActions";
import { toast } from "design-system";
import log from "loglevel";
import { createMessage, LICESNE_FETCH_ERROR } from "constants/Messages";
import { isValidResponse } from "./utilitySaga";
import { fetchAccountDetails } from "actions/AccountActions";
import { fetchLicenseDetails } from "actions/LicenseActions";
import { PLANS } from "constants/RouteContants";
import { fetchUsage } from "actions/UsageActions";
import { USAGE_STATES } from "constants/AccountTypeConstants";

export function showErrorToaster() {
  toast.show(createMessage(LICESNE_FETCH_ERROR), {
    kind: "error",
  });
}

export function* onFetchLicenseDetails() {
  try {
    const response: ApiResponse<LicenseResponseType> = yield call(
      LicenseApi.fetchCurrent,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      yield put(fetchLicenseDetailsSuccess(response.data));
      if (USAGE_STATES.includes(response.data.licenseAccountStatus))
        yield put(fetchUsage());
    } else {
      throw new Error(createMessage(LICESNE_FETCH_ERROR));
    }
  } catch (error: any) {
    log.error(error);
    yield put(fetchLicenseDetailsFailed(error.message));
    yield call(showErrorToaster);
  }

  yield put(authenticateUserSuccess());
}

export function* onStartTrial() {
  try {
    yield call(LicenseApi.startTrial);
    yield put(fetchAccountDetails());
    yield put(fetchLicenseDetails());
    if (window.location.pathname.endsWith(PLANS)) {
      window.location.replace(`${window.location.origin}/license`);
    }
  } catch (error) {
    log.error(error);
    yield put(startTrialFailed());
    yield call(showErrorToaster);
  }
}

export function* LicenseSaga() {
  yield all([
    takeLatest(ReduxActionTypes.FETCH_LICENSE, onFetchLicenseDetails),
    takeLatest(ReduxActionTypes.START_TRIAL, onStartTrial),
  ]);
}
