export enum LICENSE_ORIGIN {
  SELF_SERVE = "SELF_SERVE",
  SALES = "SALES",
  PARTNERSHIP = "PARTNERSHIP",
}

export enum LICENSE_PLAN {
  FREE = "FREE",
  BUSINESS = "BUSINESS",
  ENTERPRISE = "ENTERPRISE",
}

export enum PRODUCT_EDITION {
  COMMUNITY = "COMMUNITY",
  COMMERCIAL = "COMMERCIAL",
  AIR_GAP = "AIR_GAP",
}

export enum ENTERPRISE_LICENSE_TYPES {
  USER_USAGE = "USER_USAGE",
  USAGE = "USAGE",
  USER = "USER",
}
