import { createSelector } from "reselect";
import { AppState } from "reducers";
import { AccountStateType } from "reducers/AccountReducer";
import { getIsPaymentSuccessRedirect } from "./PaymentsSelectors";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import {
  LICENSE_ORIGIN,
  LICENSE_PLAN,
  PRODUCT_EDITION,
} from "constants/LicenseConstants";

export const getAccount = (state: AppState): AccountStateType => state.account;

export const getAccountDetails = createSelector([getAccount], (account) => {
  return account.details;
});

export const loadingAccountDetails = createSelector([getAccount], (account) => {
  return account.isLoading;
});

export const getAccountType = createSelector(
  [getAccountDetails, getIsPaymentSuccessRedirect],
  (accountDetails, isPaymentRedirect) => {
    if (accountDetails) {
      if (
        isPaymentRedirect &&
        accountDetails.licenseAccountStatus !== ACCOUNT_STATUS_TYPES.PAID_ACTIVE
      ) {
        return ACCOUNT_STATUS_TYPES.PENDING_UPDATE;
      } else if (accountDetails.licenseAccountStatus in ACCOUNT_STATUS_TYPES) {
        return accountDetails.licenseAccountStatus;
      }
    }
    return ACCOUNT_STATUS_TYPES.NOT_AVAILABLE;
  },
);

export const isFreeAccount = (state: AppState) => {
  const accountDetails = state.account?.details;
  return accountDetails?.licenseAccountStatus === ACCOUNT_STATUS_TYPES.FREE;
};

export const isBusinessAccount = (state: AppState) => {
  const accountDetails = state.account?.details;
  const licenseDetails = state.license?.details;
  return (
    accountDetails?.licenseAccountStatus !== ACCOUNT_STATUS_TYPES.FREE &&
    licenseDetails?.licensePlan === LICENSE_PLAN.BUSINESS &&
    licenseDetails?.productEdition === PRODUCT_EDITION.COMMERCIAL &&
    licenseDetails?.licenseAccountStatus in ACCOUNT_STATUS_TYPES
  );
};

export const isEnterpriseAccount = (state: AppState) => {
  const accountDetails = state.account?.details;
  const licenseDetails = state.license?.details;
  return (
    accountDetails?.licenseAccountStatus !== ACCOUNT_STATUS_TYPES.FREE &&
    licenseDetails?.licensePlan === LICENSE_PLAN.ENTERPRISE &&
    licenseDetails?.productEdition === PRODUCT_EDITION.COMMERCIAL
  );
};

export const shouldShowBusinessAccountUsage = (state: AppState) => {
  const accountDetails = state.account?.details;
  const licenseDetails = state.license?.details;
  return (
    accountDetails?.licenseAccountStatus !== ACCOUNT_STATUS_TYPES.FREE &&
    licenseDetails?.licenseOrigin === LICENSE_ORIGIN.SELF_SERVE &&
    licenseDetails?.productEdition === PRODUCT_EDITION.COMMERCIAL &&
    licenseDetails?.licenseAccountStatus in ACCOUNT_STATUS_TYPES
  );
};

export const shouldShowEnterpriseAccountUsage = (state: AppState) => {
  const accountDetails = state.account?.details;
  const licenseDetails = state.license?.details;
  return (
    accountDetails?.licenseAccountStatus !== ACCOUNT_STATUS_TYPES.FREE &&
    licenseDetails?.licenseOrigin != null &&
    licenseDetails?.licenseOrigin !== LICENSE_ORIGIN.SELF_SERVE &&
    licenseDetails?.productEdition === PRODUCT_EDITION.COMMERCIAL
  );
};

export const isAirgapAccount = (state: AppState) => {
  const accountDetails = state.account?.details;
  const licenseDetails = state.license?.details;
  return (
    accountDetails?.licenseAccountStatus !== ACCOUNT_STATUS_TYPES.FREE &&
    licenseDetails?.licensePlan === LICENSE_PLAN.ENTERPRISE &&
    licenseDetails?.productEdition === PRODUCT_EDITION.AIR_GAP
  );
};
