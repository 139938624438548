import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const RouteContainer = styled.div<{
  fullscreen: boolean;
  canShowBanner: boolean;
}>`
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  ${(props) => {
    if (props.canShowBanner) {
      return ` height: calc(100vh - 50px);`;
    } else {
      return ` height: 100vh;`;
    }
  }};
`;
