import {
  createMessage,
  SETUP_TEXT,
  PLANS_TEXT,
  BILLING_TEXT,
} from "constants/Messages";
import { useSelector } from "react-redux";
import {
  shouldShowBillingUsage,
  shouldShowPlans,
} from "selectors/LicenseSelectors";
import analyticEvents from "constants/AnalyticEvents";

export default function useSidebarItems() {
  const showBillingUsagePage = useSelector(shouldShowBillingUsage);
  const showPlansPage = useSelector(shouldShowPlans);

  const sidebarMenuItems = [
    {
      id: "license",
      text: createMessage(SETUP_TEXT),
      path: "/license",
      isVisible: true,
      icon: "key-2-line",
      segmentEvent: analyticEvents.LICENSE_PAGE_CLICK_PORTAL,
    },
    {
      id: "plans",
      text: createMessage(PLANS_TEXT),
      path: "/plans",
      isVisible: showPlansPage,
      icon: "bill-line",
      segmentEvent: analyticEvents.PLAN_BUILDER_CLICK_PORTAL,
    },
    {
      id: "billing",
      text: createMessage(BILLING_TEXT),
      path: "/billing",
      isVisible: showBillingUsagePage,
      icon: "file-paper-2-line",
      segmentEvent: analyticEvents.USAGE_PAGE_CLICK_PORTAL,
    },
  ];
  return sidebarMenuItems;
}
