export const ReduxActionTypes = {
  APP_INIT: "APP_INIT",
  APP_INIT_SUCCESS: "APP_INIT_SUCCESS",

  AUTHENTICATE_USER: "AUTHENTICATE_USER",
  AUTHENTICATE_USER_SUCCESS: "AUTHENTICATE_USER_SUCCESS",
  LOGOUT_USER: "LOGOUT_USER",

  START_TRIAL: "START_TRIAL",
  START_TRIAL_FAILED: "START_TRIAL_FAILED",

  FETCH_ACCOUNT_DETAILS: "FETCH_ACCOUNT_DETAILS",
  FETCH_ACCOUNT_DETAILS_SUCCESS: "FETCH_ACCOUNT_DETAILS_SUCCESS",

  FETCH_LICENSE: "FETCH_LICENSE",
  FETCH_LICENSE_SUCCESS: "FETCH_LICENSE_SUCCESS",
  FETCH_LICENSE_FAILED: "FETCH_LICENSE_FAILED",

  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  STORE_ACCESS_TOKEN: "STORE_ACCESS_TOKEN",
  FETCH_USER_DETAILS_SUCCESS: "FETCH_USER_DETAILS_SUCCESS",
  FETCH_USER_DETAILS_FAILED: "FETCH_USER_DETAILS_FAILED",

  FETCH_USAGE_DETAILS: "FETCH_USAGE_DETAILS",
  FETCH_USAGE_DETAILS_SUCCESS: "FETCH_USAGE_DETAILS_SUCCESS",
  FETCH_USAGE_DETAILS_FAILURE: "FETCH_USAGE_DETAILS_FAILURE",

  INITIATE_CHECKOUT: "INITIATE_CHECKOUT",
  INITIATE_CHECKOUT_FAILED: "INITIATE_CHECKOUT_FAILED",

  GO_TO_PAYMENT_DASHBOARD: "GO_TO_PAYMENT_DASHBOARD",
  GO_TO_PAYMENT_DASHBOARD_FAILED: "GO_TO_PAYMENT_DASHBOARD_FAILED",

  PAYMENT_SUCCESS_REDIRECT: "PAYMENT_SUCCESS_REDIRECT",
};

export const ReduxActionErrorTypes = {};
