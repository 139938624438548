import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { AccountApiResponse } from "types/ApiResponseTypes";
import { ReduxAction } from "types/ReduxTypes";
import { createReducer } from "utils/ReducerUtils";

export type AccountStateType = {
  details: AccountApiResponse | null | undefined;
  isLoading: boolean;
  error: string | null;
};

const initialState: AccountStateType = {
  details: null,
  error: "",
  isLoading: false,
};

export const accountReducer = createReducer(initialState, {
  [ReduxActionTypes.FETCH_ACCOUNT_DETAILS]: (state: AccountStateType) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [ReduxActionTypes.FETCH_ACCOUNT_DETAILS_SUCCESS]: (
    state: AccountStateType,
    action: ReduxAction<AccountStateType>,
  ) => {
    return {
      ...state,
      details: { ...action.payload },
      isLoading: false,
    };
  },
});
