import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { UsageDetails } from "types/UsageTypes";
import { createAction } from "utils/ActionUtils";

export const fetchUsage = () =>
  createAction(ReduxActionTypes.FETCH_USAGE_DETAILS);

export const fetchUsageSuccess = (payload: UsageDetails) =>
  createAction(ReduxActionTypes.FETCH_USAGE_DETAILS_SUCCESS, payload);

export const fetchUsageFailure = () =>
  createAction(ReduxActionTypes.FETCH_USAGE_DETAILS_FAILURE);
