import React from "react";
import CommandBox from "../CommandBox";
import { Step } from "../DocumentViewer";
import {
  GuideWrapper,
  RedStyling,
  CyanStyling,
} from "../DocumentViewer.styles";
import { Text } from "design-system";
function DockerInstallGuide() {
  return (
    <>
      <Text data-testid="docker-install-guide" kind="heading-xs">
        Follow the steps listed below to install Appsmith on Docker:
      </Text>
      <GuideWrapper>
        <Step count={1}>
          <Text>
            Download and place the <code>docker-compose.yml</code> file in the
            Appsmith installation folder or run the cURL command.
          </Text>
          <CommandBox>
            <RedStyling>curl</RedStyling> -L https://bit.ly/docker-compose-be -o{" "}
            <CyanStyling>$PWD</CyanStyling>/docker-compose.yml
          </CommandBox>
        </Step>

        <Step count={2}>
          <Text>
            Start the Docker container using the command (You may need to run
            command with <code>sudo</code>).
          </Text>
          <CommandBox>
            <RedStyling>docker-compose</RedStyling> up -d
          </CommandBox>
        </Step>

        <Step count={3}>
          <Text>
            Check the Docker logs for the message{" "}
            <code>Appsmith is Running!</code> using the command
            <br />
            <code>docker logs -f appsmith</code> and confirm that the Appsmith
            container is ready.
          </Text>
        </Step>

        <Step count={4}>
          <Text>
            Create an Appsmith account and enter your license key to activate
            the instance.
          </Text>
        </Step>
      </GuideWrapper>
    </>
  );
}
export default DockerInstallGuide;
