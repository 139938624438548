import React from "react";
import {
  Wrapper,
  StepWrapper,
  StepCount,
  StepContainer,
  OtherMethodWrapper,
  OtherMethodCard,
} from "./DocumentViewer.styles";
import DockerInstallGuide from "./DockerGuide/DockerInstallGuide";
import DockerUpgradeGuide from "./DockerGuide/DockerUpgradeGuide";
import KubernetesInstallGuide from "./KubernetesGuide/KubernetesInstallGuide";
import KubernetesUpgradeGuide from "./KubernetesGuide/KubernetesUpgradeGuide";
import {
  GUIDES,
  MAIN_TABS,
  SUB_TABS,
} from "constants/InstallationGuideConstants";
import { Icon, Text } from "design-system";
import {
  OPEN_DOCS,
  OTHER_METHODS_HEADING,
  createMessage,
} from "constants/Messages";

interface DocumentViewerProps {
  activeMainTab: MAIN_TABS;
  activeSubTab: SUB_TABS;
}
type StepProps = {
  children: React.ReactNode;
  count: number;
};

export function Step({ children, count }: StepProps) {
  return (
    <StepWrapper>
      <StepCount>
        <Text kind="heading-xs">{count}</Text>
      </StepCount>
      <StepContainer>{children}</StepContainer>
    </StepWrapper>
  );
}

function DockerGuide({ active }: { active: SUB_TABS }) {
  if (active === SUB_TABS.INSTALL) {
    return <DockerInstallGuide />;
  }
  return <DockerUpgradeGuide />;
}
function KubernetesGuide({ active }: { active: SUB_TABS }) {
  if (active === SUB_TABS.INSTALL) {
    return <KubernetesInstallGuide />;
  }
  return <KubernetesUpgradeGuide />;
}
function OtherGuide() {
  return (
    <div data-testid="other-methods-container">
      <Text className="!mb-6" kind="heading-s">
        {createMessage(OTHER_METHODS_HEADING)}
      </Text>
      <OtherMethodWrapper>
        {GUIDES.map((guide) => (
          <OtherMethodCard
            data-testid="other-method"
            key={guide.id}
            onClick={() => (window.open(guide.link), "_blank")}
          >
            <div className="flex items-center gap-4">
              <img alt={guide.id} src={guide.icon} />
              <Text data-testid="other-method-name" kind="heading-s">
                {guide.name}
              </Text>
            </div>
            <div className="docs-link flex items-center gap-1">
              <Text data-testid="other-method-name">
                {createMessage(OPEN_DOCS)}
              </Text>
              <Icon
                color="var(--ads-v2-color-fg)"
                name="share-box-line"
                size="md"
              />
            </div>
          </OtherMethodCard>
        ))}
      </OtherMethodWrapper>
    </div>
  );
}

export default function DocumentViewer({
  activeMainTab,
  activeSubTab,
}: DocumentViewerProps) {
  let activeGuide;
  if (activeMainTab === MAIN_TABS.DOCKER)
    activeGuide = <DockerGuide active={activeSubTab} />;
  else if (activeMainTab === MAIN_TABS.KUBERNETES)
    activeGuide = <KubernetesGuide active={activeSubTab} />;
  else if (activeMainTab === MAIN_TABS.OTHER) activeGuide = <OtherGuide />;

  return <Wrapper data-testid="document-viewer">{activeGuide}</Wrapper>;
}
