import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { ReduxAction } from "types/ReduxTypes";
import { UsageDetails } from "types/UsageTypes";
import { createReducer } from "utils/ReducerUtils";

export type UsageState = {
  isLoading: boolean;
  hasFailed: boolean;
  details: UsageDetails;
  stripeSubscriptionId: string;
};

const initialState: UsageState = {
  isLoading: false,
  hasFailed: false,
  details: {
    stripeCustomerId: "",
    subscriptionEndDate: 0,
    subscriptionStartDate: 0,
    maxCap: 0,
    licenseOrigin: "",
    date: 0,
    userStats: {
      usersWithUsageLessThanMaxCap: 0,
      usersWithUsageGreaterThanMaxCap: 0,
      totalUsers: 0,
    },
    sessionStats: {
      sessionsFromUsersWithUsageLessThanMaxCap: 0,
      sessionsFromUsersWithUsageGreaterThanMaxCap: 0,
      totalSessions: 0,
    },
    billedHoursStats: {
      billedHoursFromUsersWithUsageLessThanMaxCap: 0,
      billedHoursFromUsersWithUsageGreaterThanMaxCap: 0,
      totalBilledHours: 0,
    },
    billedAmountStats: {
      billedAmountFromUsersWithUsageLessThanMaxCap: 0,
      billedAmountFromUsersWithUsageGreaterThanMaxCap: 0,
      totalBilledAmount: 0,
    },
  },
  stripeSubscriptionId: "",
};

export const UsageReducer = createReducer(initialState, {
  [ReduxActionTypes.FETCH_USAGE_DETAILS]: (state: UsageState) => {
    return {
      ...state,
      isLoading: true,
      hasFailed: false,
    };
  },
  [ReduxActionTypes.FETCH_USAGE_DETAILS_SUCCESS]: (
    state: UsageState,
    action: ReduxAction<UsageDetails>,
  ) => {
    return {
      ...state,
      isLoading: false,
      hasFailed: false,
      details: action.payload,
    };
  },
  [ReduxActionTypes.FETCH_USAGE_DETAILS_FAILURE]: (state: UsageState) => {
    return {
      ...state,
      hasFailed: true,
      isLoading: false,
      details: initialState.details,
    };
  },
});
