export const FONT = {
  WEIGHT_300: 300,
  WEIGHT_400: 400,
  WEIGHT_500: 500,
  WEIGHT_600: 600,
  WEIGHT_700: 700,
  WEIGHT_800: 800,
  WEIGHT_900: 900,

  SIZE_8px: "8px",
  SIZE_10px: "10px",
  SIZE_11px: "11px",
  SIZE_12px: "12px",
  SIZE_13px: "13px",
  SIZE_14px: "14px",
  SIZE_15px: "15px",
  SIZE_16px: "16px",
  SIZE_18px: "18px",
  SIZE_20px: "20px",
  SIZE_28px: "28px",
  SIZE_30px: "30px",
  SIZE_32px: "32px",
  SIZE_40px: "40px",
};
