import { AppState } from "reducers";
import { createSelector } from "reselect";
import { getLicense } from "./LicenseSelectors";

export const usageReducer = (state: AppState) => state.usage;

export const getUsageTillDate = createSelector([usageReducer], (usageSlice) => {
  return usageSlice.details?.date;
});

export const getSubscriptionStartDate = createSelector(
  [usageReducer],
  (usageSlice) => {
    return usageSlice.details?.subscriptionStartDate;
  },
);
export const getSubscriptionEndDate = createSelector(
  [usageReducer],
  (usageSlice) => {
    return usageSlice.details?.subscriptionEndDate;
  },
);

export const getMaxCap = createSelector([usageReducer], (usageSlice) => {
  return usageSlice.details?.maxCap;
});

export const getIsUsageAvailable = createSelector(
  [usageReducer],
  (usageSlice) => {
    return !usageSlice.hasFailed;
  },
);

export const getIsUsageLoading = createSelector(
  [usageReducer],
  (usageSlice) => {
    return usageSlice.isLoading;
  },
);

export const getStripeCustomerId = createSelector([getLicense], (license) => {
  return license.details?.stripeCustomerId;
});

export const getSelfServeUsageDetails = createSelector(
  [usageReducer],
  (usageSlice) => {
    const { details } = usageSlice;
    const hoursUsed = details?.sessionStats?.totalSessions ?? 0;
    const activeUsers = details?.userStats?.totalUsers ?? 0;
    const prepaidHours = details?.purchasedUsage?.sessions ?? 0;
    const overageHours = details?.overages ?? 0;
    const hoursBilled = prepaidHours + overageHours;

    return {
      hoursUsed,
      activeUsers,
      prepaidHours,
      overageHours,
      hoursBilled,
    };
  },
);
export const getUsageDetailsForEnterprise = createSelector(
  [usageReducer],
  (usageSlice) => {
    const seatsPurchased = usageSlice.details?.purchasedUsage?.users;
    const activeUsers = usageSlice.details?.userStats?.totalUsers;
    const hoursUsed =
      usageSlice.details?.billedHoursStats?.totalBilledHours ?? 0;
    const hoursPurchased = usageSlice.details?.purchasedUsage?.sessions ?? 0;
    const hoursFreeSessions =
      usageSlice.details?.purchasedUsage?.freeSessions ?? 0;
    return {
      seatsPurchased: seatsPurchased,
      activeUsers: activeUsers,
      hoursUsed: hoursUsed,
      hoursPurchased: hoursPurchased,
      hoursFreeSessions: hoursFreeSessions,
    };
  },
);

export const getEnterpriseSubscriptionType = createSelector(
  [usageReducer],
  (usageSlice) => {
    return usageSlice.details?.subscriptionType;
  },
);

export const getEnterpriseDate = createSelector(
  [usageReducer],
  (usageSlice) => {
    return {
      startDate: usageSlice.details?.subscriptionStartDate,
      endDate: usageSlice.details?.subscriptionEndDate,
    };
  },
);
