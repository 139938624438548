import { fetchUsageFailure, fetchUsageSuccess } from "actions/UsageActions";
import UsageApi from "api/UsageApi";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getStripeCustomerId } from "selectors/UsageSelectors";
import { ApiResponse } from "types/ApiTypes";
import { UsageDetails } from "types/UsageTypes";
import { isValidResponse } from "./utilitySaga";

export function* fetchUsage() {
  try {
    const stripeCustomerId: string = yield select(getStripeCustomerId);

    const response: ApiResponse<UsageDetails> = yield call(
      UsageApi.get,
      stripeCustomerId,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      yield put(fetchUsageSuccess(response.data));
    } else {
      yield put(fetchUsageFailure());
    }
  } catch (e) {
    yield put(fetchUsageFailure());
  }
}

export function* UsageSaga() {
  yield all([takeLatest(ReduxActionTypes.FETCH_USAGE_DETAILS, fetchUsage)]);
}
