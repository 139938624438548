import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FONT } from "constants/styles";

export const SIDE_BAR_WIDTH = 260;

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  text-align: left;
  border-right: 1px solid var(--ads-v2-color-border);
  height: calc(100vh - 46px);
  background-color: var(--ads-v2-color-bg);
  width: ${SIDE_BAR_WIDTH}px;
  justify-content: space-between;
`;

export const ListContainer = styled.div`
  margin-top: 8px;
`;

export const LinkItem = styled(NavLink)`
  font-weight: ${FONT.WEIGHT_400};
  font-size: ${FONT.SIZE_14px};
  line-height: 22px;
  text-decoration: none;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  &:hover {
    background: var(--ads-v2-color-bg-muted);
    text-decoration: none !important;
  }
  &:focus {
    outline: none;
  }
  &.active {
    background: var(--ads-v2-color-bg-muted);
    font-weight: ${FONT.WEIGHT_500};
  }
  svg {
    margin-right: 10px;
  }
`;

export const SidebarUpgradeBanner = styled.div`
  padding: 20px 12px;
  border-radius: 4px;
  text-align: center;
`;
