import React, { useState } from "react";
import { Container } from "./LicenseActions.styles";
import { Button, Tooltip } from "design-system";
import { useDispatch, useSelector } from "react-redux";
import {
  loadingLicenseDetails,
  getLicenseDetails,
  isLicenseOriginSelfServe,
} from "selectors/LicenseSelectors";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import {
  COPY_TO_CLIPBOARD,
  createMessage,
  HIDE_LICENSE,
  SHOW_LICENSE,
  UPGRADE_CTA,
  LICENSE_COPIED_TO_CLIPBOARD,
  PAYMENT_SUCCESSFULL_RETRY,
  CONTACT_US_CTA,
} from "constants/Messages";
import Analytics from "thirdparty/analytics";
import analyticEvents from "constants/AnalyticEvents";
import { fetchLicenseDetails } from "actions/LicenseActions";
import {
  getAccountType,
  isEnterpriseAccount,
} from "selectors/AccountSelectors";
import { fetchAccountDetails } from "actions/AccountActions";
import {
  startConversationOnIntercom,
  textForIntercomMessage,
} from "utils/IntercomUtils";
import { initiateCheckout } from "actions/paymentActions";
import { getIsBeingRedirectedToCheckout } from "selectors/PaymentsSelectors";

type LicenseActionsProps = {
  showLicenseKey: boolean;
  toggleShowLicenseKey: () => void;
};

function LicenseActions({
  showLicenseKey,
  toggleShowLicenseKey,
}: LicenseActionsProps) {
  const dispatch = useDispatch();
  const accountType = useSelector(getAccountType);
  const license = useSelector(getLicenseDetails);
  const isLicenseLoading = useSelector(loadingLicenseDetails);
  const isEnterprise = useSelector(isEnterpriseAccount);
  const isSelfServe = useSelector(isLicenseOriginSelfServe);
  const isBeingRedirectedToCheckout = useSelector(
    getIsBeingRedirectedToCheckout,
  );
  const [licenseCopyText, setLicenseCopyText] = useState(
    createMessage(COPY_TO_CLIPBOARD),
  );

  function handleUpdateClicked() {
    Analytics.track(analyticEvents.UPGRADE_CLICK_PORTAL_LICENSE_PAGE);
    dispatch(initiateCheckout());
  }

  function handleCopyClicked() {
    if (license?.licenseKey) {
      navigator.clipboard.writeText(license?.licenseKey);
      setLicenseCopyText(createMessage(LICENSE_COPIED_TO_CLIPBOARD));
      setTimeout(() => {
        setLicenseCopyText(createMessage(COPY_TO_CLIPBOARD));
      }, 2000);
    }
  }

  function handleRetry() {
    dispatch(fetchAccountDetails());
    dispatch(fetchLicenseDetails());
  }

  const showIntercomWithText = () => {
    const message = textForIntercomMessage(
      accountType as ACCOUNT_STATUS_TYPES,
      isEnterprise,
    );
    if (message) {
      startConversationOnIntercom(message);
    }
  };

  if (accountType === ACCOUNT_STATUS_TYPES.PENDING_UPDATE) {
    return (
      <Button
        isLoading={isLicenseLoading}
        kind="secondary"
        onClick={handleRetry}
        size="md"
      >
        {createMessage(PAYMENT_SUCCESSFULL_RETRY)}
      </Button>
    );
  } else if (
    (!isSelfServe && accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED) ||
    (isEnterprise && accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED)
  ) {
    return (
      <Button kind="primary" onClick={showIntercomWithText} size="md">
        {createMessage(CONTACT_US_CTA)}
      </Button>
    );
  } else if (
    accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED ||
    accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED
  ) {
    return (
      <Button
        data-testid="upgrade-button"
        isLoading={isBeingRedirectedToCheckout}
        kind="primary"
        onClick={handleUpdateClicked}
        size="md"
      >
        {createMessage(UPGRADE_CTA)}
      </Button>
    );
  } else if (!license) {
    return null;
  } else {
    return (
      <Container>
        <Tooltip
          content={
            showLicenseKey
              ? createMessage(HIDE_LICENSE)
              : createMessage(SHOW_LICENSE)
          }
        >
          <Button
            data-testid="license-toggle"
            isIconButton
            kind="tertiary"
            onClick={toggleShowLicenseKey}
            size="md"
            startIcon={showLicenseKey ? "eye-off" : "eye-on"}
          />
        </Tooltip>
        <Tooltip content={licenseCopyText}>
          <Button
            isIconButton
            kind="tertiary"
            onClick={handleCopyClicked}
            size="md"
            startIcon="duplicate"
          />
        </Tooltip>
        {isEnterprise &&
          (accountType === ACCOUNT_STATUS_TYPES.PAID_IN_GRACE_PERIOD ||
            accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE) && (
            <Button
              className="ml-2"
              kind="primary"
              onClick={showIntercomWithText}
              size="md"
            >
              {createMessage(CONTACT_US_CTA)}
            </Button>
          )}
        {!isEnterprise && accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE && (
          <Button
            className="ml-2"
            data-testid="upgrade-button"
            isLoading={isBeingRedirectedToCheckout}
            kind="primary"
            onClick={handleUpdateClicked}
            size="md"
          >
            {createMessage(UPGRADE_CTA)}
          </Button>
        )}
      </Container>
    );
  }
}

export default LicenseActions;
