import { combineReducers } from "redux";

import { LicenseStateType, licenseReducer } from "./LicenseReducer";
import { UserStateType, userReducer } from "./UserReducer";
import { UsageState, UsageReducer } from "./UsageReducer";
import { PaymentsState, paymentsReducer } from "./PaymentsReducer";
import { AccountStateType, accountReducer } from "./AccountReducer";

export interface AppState {
  account: AccountStateType;
  license: LicenseStateType;
  user: UserStateType;
  usage: UsageState;
  payments: PaymentsState;
}

export const rootReducer = combineReducers({
  account: accountReducer,
  license: licenseReducer,
  user: userReducer,
  usage: UsageReducer,
  payments: paymentsReducer,
});
