import React from "react";
import { ACCOUNT_STATUS_TYPES } from "constants/AccountTypeConstants";
import {
  createMessage,
  NOT_AVAILABLE,
  PAYMENT_SUCCESSFULL_MESSAGE,
  YOUR_LICENSE_KEY_HAS_EXPIRED,
  YOUR_LICENSE_KEY_TEXT,
} from "constants/Messages";
import LicenseValidity from "features/license-validity/LicenseValidity";
import { useSelector } from "react-redux";
import {
  getLicensePlan,
  getProductEdition,
  getLicenseDetails,
} from "selectors/LicenseSelectors";
import {
  Container,
  LicenseKeyHeading,
  LicenseKeyValue,
} from "./LicenseKey.styles";
import { Tag, Text } from "design-system";
import { getLicensePlanText } from "utils/LicenseUtils";
import { getAccountType } from "selectors/AccountSelectors";

type LicenseKeyProps = {
  showLicenseKey: boolean;
};

function LicenseData({ showLicenseKey }: LicenseKeyProps) {
  const license = useSelector(getLicenseDetails);
  const licensePlan = useSelector(getLicensePlan);
  const productEdition = useSelector(getProductEdition);
  const accountType = useSelector(getAccountType);

  return (
    <>
      <LicenseKeyHeading>
        <Text className="!font-semibold" kind="action-l">
          {license
            ? createMessage(YOUR_LICENSE_KEY_TEXT)
            : createMessage(NOT_AVAILABLE)}
        </Text>
        <Tag isClosable={false}>
          {getLicensePlanText(licensePlan, productEdition)}{" "}
          {accountType === ACCOUNT_STATUS_TYPES.TRIAL_ACTIVE && ` • Trial`}
        </Tag>
      </LicenseKeyHeading>
      {license && (
        <LicenseKeyValue
          className="!font-semibold"
          data-testid="license-key"
          kind="body-m"
        >
          {showLicenseKey
            ? license.licenseKey
            : license.licenseKey.replace(/[^-]/g, "*").replaceAll("-", " ")}
        </LicenseKeyValue>
      )}
      <LicenseValidity />
    </>
  );
}

function LicenseKey({ showLicenseKey }: LicenseKeyProps) {
  const accountType = useSelector(getAccountType);

  let content;

  if (accountType === ACCOUNT_STATUS_TYPES.PENDING_UPDATE) {
    content = (
      <Text kind="heading-s">{createMessage(PAYMENT_SUCCESSFULL_MESSAGE)}</Text>
    );
  } else if (
    accountType === ACCOUNT_STATUS_TYPES.TRIAL_EXPIRED ||
    accountType === ACCOUNT_STATUS_TYPES.PAID_EXPIRED
  ) {
    content = (
      <Text data-testid="license-key-expired" kind="heading-s">
        {createMessage(YOUR_LICENSE_KEY_HAS_EXPIRED)}
      </Text>
    );
  } else {
    content = <LicenseData showLicenseKey={showLicenseKey} />;
  }

  return <Container>{content}</Container>;
}

export default LicenseKey;
