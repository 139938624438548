import React from "react";
import styled from "styled-components";

const ProgressBg = styled.div`
  background-color: var(--ads-v2-color-bg-subtle);
  display: flex;
  overflow: hidden;
`;

const ProgressFg1 = styled.div`
  background-color: var(--ads-v2-color-fg);
`;

const ProgressFg2 = styled.div`
  background-color: var(--ads-v2-color-bg-brand);
`;

interface ProgressBarProps {
  fg1Progress?: number;
  fg2Progress?: number;
}

function ProgressBar({ fg1Progress = 0, fg2Progress = 0 }: ProgressBarProps) {
  return (
    <div className="mb-3">
      <ProgressBg
        className="w-full rounded-full h-2.5"
        data-testid="hours-progress-bar"
      >
        <ProgressFg1
          className="h-2.5"
          style={{
            width: `${fg1Progress}%`,
            borderTopRightRadius: !fg2Progress ? "40px" : 0,
            borderBottomRightRadius: !fg2Progress ? "40px" : 0,
          }}
        />
        <ProgressFg2
          className="h-2.5"
          style={{
            width: `${fg2Progress}%`,
            borderTopRightRadius: "40px",
            borderBottomRightRadius: "40px",
          }}
        />
      </ProgressBg>
    </div>
  );
}

export default ProgressBar;
