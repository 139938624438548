import { AxiosPromise } from "axios";
import { ApiResponse } from "types/ApiTypes";
import { LicenseResponseType } from "types/LicenseTypes";
import API from "./Api";
class LicenseApi {
  static async fetchCurrent(): AxiosPromise<ApiResponse<LicenseResponseType>> {
    return API.get("/license");
  }
  static async startTrial(): AxiosPromise<ApiResponse<LicenseResponseType>> {
    return API.post("/license");
  }
}

export default LicenseApi;
