import {
  goToPaymentDashboardFailed,
  initiateCheckoutFailed,
} from "actions/paymentActions";
import PaymentsApi from "api/PaymentsApi";
import analyticEvents from "constants/AnalyticEvents";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { toast } from "design-system";
import { all, call, put, takeLatest } from "redux-saga/effects";
import Analytics from "thirdparty/analytics";
import log from "loglevel";
import { isValidResponse } from "./utilitySaga";
import { ApiResponse } from "types/ApiTypes";
import { CheckoutResponse, DashboardRepsonse } from "types/paymentsTypes";
import { createMessage, GENERIC_ERROR_MESSAGE } from "constants/Messages";

export function* initiateCheckout() {
  yield call(Analytics.track, analyticEvents.CHECKOUT_CLICK_PORTAL);

  try {
    const response: ApiResponse<CheckoutResponse> = yield call(
      PaymentsApi.initiateCheckout,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      window.location.assign(response.data.checkoutUrl);
    } else {
      throw new Error();
    }
  } catch (e) {
    log.error(e);

    yield put(initiateCheckoutFailed());

    toast.show(createMessage(GENERIC_ERROR_MESSAGE), {
      kind: "error",
    });
  }
}

export function* goToPaymentDashboard() {
  try {
    const response: ApiResponse<DashboardRepsonse> = yield call(
      PaymentsApi.goToDashboard,
    );

    const isValid: boolean = yield call(isValidResponse, response);

    if (isValid) {
      window.open(response.data.customerDashboardUrl, "_blank");
    } else {
      throw new Error();
    }
  } catch (e) {
    log.error(e);

    yield put(goToPaymentDashboardFailed());

    toast.show(createMessage(GENERIC_ERROR_MESSAGE), {
      kind: "error",
    });
  }
}

export function* PaymentsSaga() {
  yield all([
    takeLatest(ReduxActionTypes.INITIATE_CHECKOUT, initiateCheckout),
    takeLatest(ReduxActionTypes.GO_TO_PAYMENT_DASHBOARD, goToPaymentDashboard),
  ]);
}
