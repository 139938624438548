import styled from "styled-components";

export const StyledCommand = styled.div`
  padding: 16px;
  background-color: var(--ads-v2-color-bg-subtle);
  border-radius: 8px;
  margin-top: 8px;
  font-family: monospace !important;
  width: 100%;
  position: relative;
  &:hover {
    .copy-command {
      display: block;
      opacity: 0.5;
    }
  }
`;
export const Container = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
  background-color: var(--ads-v2-color-bg-subtle);
  border-radius: 4px;
  padding: 2px;
  display: none;
  cursor: pointer;
  &.copy-command:hover {
    opacity: 1;
  }
`;
