import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--ads-v2-color-border);
  background-color: var(--ads-v2-color-bg);
  margin-top: 12px;
`;
export const LoaderContainer = styled.div`
  width: 100%;
  height: 91px;
  position: relative;
`;
export const DetailsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;
