import { Colors } from "constants/Colors";
import { FONT } from "constants/styles";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const GuideWrapper = styled.div`
  max-width: 780px;
  margin-top: 16px;
  code {
    background: var(--ads-v2-color-black-100);
    color: ${Colors.CODE_BLACK};
    font-family: monospace !important;
    padding: 1px 4px !important;
    word-break: break-word !important;
    font-size: ${FONT.SIZE_14px};
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 24px;
`;

export const StepCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background-color: var(--ads-v2-color-bg-muted);
  border-radius: 100%;
`;

export const StepContainer = styled.div`
  display: block;
  width: 100%;
`;
export const RedStyling = styled.span`
  color: var(--ads-v2-color-fg-on-error);
`;
export const CyanStyling = styled.span`
  color: ${Colors.CYAN_200};
`;
export const BlueStyling = styled.span`
  color: ${Colors.COMMAND_BLUE};
`;
export const PinkStyling = styled.span`
  color: ${Colors.COMMAND_PINK};
`;
export const Comment = styled.span`
  color: var(--ads-v2-color-black-500);
  font-style: italic;
`;

export const OtherMethodWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;
export const OtherMethodCard = styled.div`
  max-width: 460px;
  min-width: 430px;
  height: 82px;
  padding: 16px 24px;
  border-radius: 10px;
  border: 1px solid var(--ads-v2-color-border-muted);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  img {
    width: 50px;
    height: 50px;
  }
  .docs-link {
    display: none;
  }
  &:hover {
    background-color: var(--ads-v2-color-bg-subtle);
    .docs-link {
      display: inherit;
    }
  }
`;
