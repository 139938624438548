// Color Names generated from http://chir.ag/projects/name-that-color
export const Colors = {
  WARNING_ORANGE: "#FFF8E2",
  BLUEISH_BLACK: "#111928",
  BLUEISH_GRAY: "#6b7280",
  REDDISH_WHITE: "#ebebeb",
  LIGHT_PURPLE: "#605BC8",
  ORANGISH_WHITE: "#fff9f7",
  PURPLE_WHITE: "#e7e6f7",
  LIGHT_STEEL_GRAY: "#F5F6F6",
  LIGHT_BROWN: "#6B412E",
  CODE_BLACK: "#37474f",
  COMMAND_BLUE: "#00a4db",
  COMMAND_PINK: "#e3116c",
  CYAN_200: "#36acaa",
  ERROR_50: "#FFE9E9",
  ORANGE_50: "#FFF5F2",
};

export type Color = (typeof Colors)[keyof typeof Colors];
