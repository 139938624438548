import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { isNil, isObject } from "lodash";
import { getToken } from "selectors/UserSelectors";
import store from "store";

export const requestInterceptor = (config: AxiosRequestConfig) => {
  const token = getToken(store.getState());

  if (config.headers) {
    (config.headers as any).Authorization = `Bearer ${token}`;
  }

  return config;
};

export const responseSuccessInterceptor = (res: AxiosResponse) => {
  return res.data;
};

export const responseFailureInterceptor = (res: AxiosError) => {
  if (res.response?.status === 401) {
    return Promise.reject({
      responseMeta: {
        status: res.response.status,
        message: res.message,
      },
    });
  }

  return Promise.reject(res.response?.data);
};

export function convertObjectToQueryParams(
  object: Record<string, string>,
): string {
  if (!isNil(object) && isObject(object)) {
    return "?" + new URLSearchParams(object).toString();
  } else {
    return "";
  }
}

export function getISOFormatDateString(date: Date) {
  return date.toISOString().split("T")[0];
}
