import { AxiosPromise } from "axios";
import { ApiResponse } from "types/ApiTypes";

import API from "./Api";

class PaymentsApi {
  static baseUrl = "/payment";

  static async initiateCheckout(): AxiosPromise<ApiResponse<unknown>> {
    return API.post(`${PaymentsApi.baseUrl}/checkout`);
  }

  static async goToDashboard(): AxiosPromise<ApiResponse<string>> {
    return API.get(`${PaymentsApi.baseUrl}/dashboard`);
  }
}

export default PaymentsApi;
