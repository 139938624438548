import React from "react";
import { Icon, Text, Tooltip } from "design-system";

interface TextWithTooltipProps {
  text: string;
  value?: number;
  tooltipText?: string;
  small?: boolean;
  "data-testid"?: string;
}

function TextWithTooltip({
  "data-testid": dataTestId,
  small = false,
  text,
  tooltipText,
  value,
}: TextWithTooltipProps) {
  const isValuePresent = !!value || value === 0;
  return (
    <div className="flex" data-testid={dataTestId}>
      <Text
        className="!mr-1"
        color={
          small ? "var(--ads-v2-color-gray-500)" : "var(--ads-v2-color-fg)"
        }
        kind={small ? "body-s" : "body-m"}
        renderAs="span"
        style={{ lineHeight: small ? "1" : undefined }}
      >
        {text}
      </Text>
      {!!tooltipText && (
        <Tooltip content={tooltipText}>
          <Icon
            className={`${isValuePresent ? "mr-3" : ""}`}
            name="question"
            size={small ? "sm" : "md"}
          />
        </Tooltip>
      )}
      {isValuePresent && <span className="font-medium">{value}</span>}
    </div>
  );
}

export default TextWithTooltip;
