export enum FEATURE_SECTION_NAME {
  BUILD = "Build",
  DEPLOY_MANAGE = "Deploy and manage",
  ADMIN_SECURE = "Administer and secure",
  SUPPORT = "Support",
}

export enum FEATURE_NAME {
  USER = "Users",
  APPS = "Apps",
  DATASOURCES = "Datasources",
  CUSTOM_CODE = "Custom code",
  CUSTOM_BRANDING = "Custom branding",
  EMBEDDING = "Embedding",
  DATASOURCE_ENVIRONMENTS = "Datasource environments",
  VERSIONING_GIT = "Versioning with Git",
  SSO_IDP = "SSO & IDPs",
  BACKUP_RESTORE = "Backup & Restore",
  AIRGAPPED = "Airgapped edition",
  SCIM = "User provision through SCIM",
  AUDIT_LOGS = "Audit logs",
  ACCESS_CONTROLS = "Access controls",
  TECH_SUPPORT = "Technical support",
  APP_DEVELOPMENT = "App development/migration",
}

export enum PLAN_NAME {
  COMMUNITY = "Community",
  BUSINESS = "Business",
  ENTERPRISE = "Enterprise",
}

export const ALL_PLANS = [
  {
    name: PLAN_NAME.COMMUNITY,
    iconName: "community-plan.svg",
    details: ["Free forever. Unlimited users."],
    features: {
      [FEATURE_SECTION_NAME.BUILD]: [
        { [FEATURE_NAME.USER]: "Unlimited" },
        { [FEATURE_NAME.APPS]: "Unlimited" },
        { [FEATURE_NAME.DATASOURCES]: true },
        { [FEATURE_NAME.CUSTOM_CODE]: true },
        { [FEATURE_NAME.CUSTOM_BRANDING]: false },
        { [FEATURE_NAME.EMBEDDING]: "Embed public apps" },
      ],
      [FEATURE_SECTION_NAME.DEPLOY_MANAGE]: [
        { [FEATURE_NAME.DATASOURCE_ENVIRONMENTS]: false },
        { [FEATURE_NAME.VERSIONING_GIT]: "3 repos" },
        { [FEATURE_NAME.SSO_IDP]: "Google and GitHub OAuth" },
        {
          [FEATURE_NAME.BACKUP_RESTORE]:
            "When updating to a higher Appsmith version",
        },
        { [FEATURE_NAME.AIRGAPPED]: false },
        { [FEATURE_NAME.SCIM]: false },
      ],
      [FEATURE_SECTION_NAME.ADMIN_SECURE]: [
        { [FEATURE_NAME.AUDIT_LOGS]: false },
        { [FEATURE_NAME.ACCESS_CONTROLS]: "3 standard pre-defined roles" },
      ],
      [FEATURE_SECTION_NAME.SUPPORT]: [
        { [FEATURE_NAME.TECH_SUPPORT]: "Community support on Discord" },
        { [FEATURE_NAME.APP_DEVELOPMENT]: false },
      ],
    },
  },
  {
    name: PLAN_NAME.BUSINESS,
    iconName: "business-plan.svg",
    details: [
      "<span>$40/month</span> for 100 hours of usage.",
      "Additional usage at <span>$0.40</span> per hour",
      "capped at <span>$20</span> per user per month",
    ],
    features: {
      [FEATURE_SECTION_NAME.BUILD]: [
        { [FEATURE_NAME.USER]: "Unlimited" },
        { [FEATURE_NAME.APPS]: "Unlimited" },
        { [FEATURE_NAME.DATASOURCES]: true },
        { [FEATURE_NAME.CUSTOM_CODE]: true },
        { [FEATURE_NAME.CUSTOM_BRANDING]: true },
        { [FEATURE_NAME.EMBEDDING]: "Embed public apps" },
      ],
      [FEATURE_SECTION_NAME.DEPLOY_MANAGE]: [
        { [FEATURE_NAME.DATASOURCE_ENVIRONMENTS]: true },
        { [FEATURE_NAME.VERSIONING_GIT]: "Unlimited repos" },
        {
          [FEATURE_NAME.SSO_IDP]: "Google & Github OAuth",
        },
        { [FEATURE_NAME.BACKUP_RESTORE]: "Anytime you want, synced to AWS S3" },
        { [FEATURE_NAME.AIRGAPPED]: false },
        { [FEATURE_NAME.SCIM]: false },
      ],
      [FEATURE_SECTION_NAME.ADMIN_SECURE]: [
        { [FEATURE_NAME.AUDIT_LOGS]: true },
        {
          [FEATURE_NAME.ACCESS_CONTROLS]:
            "Granular, customizable permissions by roles and attributes",
        },
      ],
      [FEATURE_SECTION_NAME.SUPPORT]: [
        { [FEATURE_NAME.TECH_SUPPORT]: "Priority e-mail and chat support " },
        { [FEATURE_NAME.APP_DEVELOPMENT]: false },
      ],
    },
  },
  {
    name: PLAN_NAME.ENTERPRISE,
    iconName: "enterprise-plan.svg",
    details: ["Custom Pricing"],
    features: {
      [FEATURE_SECTION_NAME.BUILD]: [
        { [FEATURE_NAME.USER]: "Unlimited" },
        { [FEATURE_NAME.APPS]: "Unlimited" },
        { [FEATURE_NAME.DATASOURCES]: true },
        { [FEATURE_NAME.CUSTOM_CODE]: true },
        { [FEATURE_NAME.CUSTOM_BRANDING]: true },
        { [FEATURE_NAME.EMBEDDING]: "Embed private apps" },
      ],
      [FEATURE_SECTION_NAME.DEPLOY_MANAGE]: [
        { [FEATURE_NAME.DATASOURCE_ENVIRONMENTS]: true },
        { [FEATURE_NAME.VERSIONING_GIT]: "Unlimited repos" },
        {
          [FEATURE_NAME.SSO_IDP]:
            "Okta, Auth0, Active Directory and any SAML, OIDC, OAuth",
        },
        { [FEATURE_NAME.BACKUP_RESTORE]: "Anytime you want, synced to AWS S3" },
        { [FEATURE_NAME.AIRGAPPED]: true },
        { [FEATURE_NAME.SCIM]: true },
      ],
      [FEATURE_SECTION_NAME.ADMIN_SECURE]: [
        { [FEATURE_NAME.AUDIT_LOGS]: true },
        {
          [FEATURE_NAME.ACCESS_CONTROLS]:
            "Granular, customizable permissions by roles and attributes",
        },
      ],
      [FEATURE_SECTION_NAME.SUPPORT]: [
        {
          [FEATURE_NAME.TECH_SUPPORT]:
            "Dedicated support team with custom SLAs",
        },
        {
          [FEATURE_NAME.APP_DEVELOPMENT]:
            "Dedicated customer solutions engineer",
        },
      ],
    },
  },
];

export enum PLAN_TYPES {
  BUSINESS = "business",
  ENTERPRISE = "enterprise",
}

export const PLANS_TABLE_STRUCTURE = [
  {
    name: FEATURE_SECTION_NAME.BUILD,
    features: [
      FEATURE_NAME.USER,
      FEATURE_NAME.APPS,
      FEATURE_NAME.DATASOURCES,
      FEATURE_NAME.CUSTOM_CODE,
      FEATURE_NAME.CUSTOM_BRANDING,
      FEATURE_NAME.EMBEDDING,
    ],
  },
  {
    name: FEATURE_SECTION_NAME.DEPLOY_MANAGE,
    features: [
      FEATURE_NAME.DATASOURCE_ENVIRONMENTS,
      FEATURE_NAME.VERSIONING_GIT,
      FEATURE_NAME.SSO_IDP,
      FEATURE_NAME.BACKUP_RESTORE,
      FEATURE_NAME.AIRGAPPED,
      FEATURE_NAME.SCIM,
    ],
  },
  {
    name: FEATURE_SECTION_NAME.ADMIN_SECURE,
    features: [FEATURE_NAME.AUDIT_LOGS, FEATURE_NAME.ACCESS_CONTROLS],
  },
  {
    name: FEATURE_SECTION_NAME.SUPPORT,
    features: [FEATURE_NAME.TECH_SUPPORT, FEATURE_NAME.APP_DEVELOPMENT],
  },
];
