import styled from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  border: solid 1px var(--ads-v2-color-bg-muted);
  width: 100%;
  border-bottom: none;
  .fixed-row {
    position: absolute;
    top: 0;
    left: 24px;
    right: 29px;
    margin: 0 auto;
    max-width: 1132px;
    border-left: solid 1px var(--ads-v2-color-bg-muted);
    border-right: solid 1px var(--ads-v2-color-bg-muted);
    z-index: 1;
  }
  .heading {
    display: flex;
    .heading-row {
      flex: 1;
      border-bottom: solid 1px var(--ads-v2-color-bg-muted);
      border-right: solid 1px var(--ads-v2-color-bg-muted);
      padding: 16px 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: var(--ads-v2-color-bg);
      &:last-child {
        border-right: none;
      }
      &.active-plan {
        background-color: var(--ads-v2-color-bg-subtle);
      }
    }
  }
  .heading-description-row {
    height: 200px;
    border-bottom: solid 1px var(--ads-v2-color-bg-muted);
    font-size: 14px;
    font-weight: normal;
    line-height: 1.43;
    display: flex;
    span {
      font-weight: 500;
    }
    .heading-column {
      padding: 16px 24px;
      flex: 1;
      border-right: solid 1px var(--ads-v2-color-bg-muted);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:last-child {
        border-right: none;
      }
      &.active-plan {
        background-color: var(--ads-v2-color-bg-subtle);
      }
    }
  }
  .section-row {
    height: 36px;
    background-color: var(--ads-v2-color-bg-muted);
    border-bottom: solid 1px var(--ads-v2-color-bg-muted);
    padding: 6px 24px;
  }
  .feature-row {
    border-bottom: solid 1px var(--ads-v2-color-bg-muted);
    display: flex;
    align-items: stretch;
  }
  .table-column-container {
    display: flex;
  }
  .table-column {
    flex: 1;
    border-right: solid 1px var(--ads-v2-color-bg-muted);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 24px;
    align-items: flex-start;
    &:last-child {
      border-right: none;
    }
    &.active-plan {
      background-color: var(--ads-v2-color-bg-subtle);
    }
  }
`;
